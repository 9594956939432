import React, { useState } from 'react';
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './CertificatesStyle';
import CertificateCard from '../Cards/CertificateCard';

import { certificates } from '../../data/constants';

const Certificates = () => {
  const [toggle, setToggle] = useState('all');
  return (
    <Container>
      <Wrapper>
        <Title>Other Certificates</Title>
        <Desc>
          Here is a list of certificates I've recently received, when finished appropriate learning courses.
        </Desc>
        <ToggleButtonGroup >
          {toggle === 'all' ?
            <ToggleButton active value="all" onClick={() => setToggle('all')}>ALL</ToggleButton>
            :
            <ToggleButton value="all" onClick={() => setToggle('all')}>ALL</ToggleButton>
          }
          <Divider />
          {toggle === 'frontend' ?
            <ToggleButton active value="frontend" onClick={() => setToggle('frontend')}>FRONTEND</ToggleButton>
            :
            <ToggleButton value="frontend" onClick={() => setToggle('frontend')}>FRONTEND</ToggleButton>
          }
          <Divider />
          {toggle === 'backend' ?
            <ToggleButton active value="backend" onClick={() => setToggle('backend')}>BACKEND</ToggleButton>
            :
            <ToggleButton value="backend" onClick={() => setToggle('backend')}>BACKEND</ToggleButton>
          }
          <Divider />
          {toggle === 'other' ?
            <ToggleButton active value="other" onClick={() => setToggle('other')}>OTHER</ToggleButton>
            :
            <ToggleButton value="other" onClick={() => setToggle('other')}>OTHER</ToggleButton>
          }
        </ToggleButtonGroup>
        <CardContainer>
          {toggle === 'all' && certificates
            .map((certificate, index) => (
              <CertificateCard certificate={certificate} key={index} />
            ))}
          {certificates
            .filter((item) => item.category === toggle)
            .map((certificate, index) => (
              <CertificateCard certificate={certificate} key={index} />
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  )
}

export default Certificates
