export const darkTheme = {
    bg:"#1d3557",
    primary:"#FDB515",
    blue:"#48C6EF",
    violet:"#6F86D6",
    text_primary:"#F9F7F3",
    text_secondary:"#F9F7F3",
    card:"#14213D",
    card_light: '#14213D',
    button:"#7D8CC4",
    white:"#FFFFFF",
    black:"#000000",
}
