/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import { Nav, NavLink, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, LinkedInButton, ButtonContainer, MobileIcon, MobileMenu, MobileLink, LinkedInImage, GitHubImage } from './NavbarStyledComponent'
import { DiCode } from 'react-icons/di';
import { FaBars } from 'react-icons/fa';
import { AiOutlineClose } from "react-icons/ai";
import { Bio } from '../../data/constants';

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      event.target !== buttonRef.current
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to='/'>
          <div style={{ display: "flex", alignItems: "center", color: "white", cursor: 'pointer' }}>
            <DiCode size="3rem" /> <Span>VALENTYN ARKHO</Span>
          </div>
        </NavLogo>
        <MobileIcon>
          {!isOpen ? (
            <div ref={buttonRef} onClick={() => setIsOpen(!isOpen)}>
              <FaBars /> 
            </div>
           ) : (
            <AiOutlineClose onClick={() => {
              setIsOpen(!isOpen)
            }} />
           )
          }
        </MobileIcon>
        <NavItems>
          <NavLink href="#about">About</NavLink>
          <NavLink href='#skills'>Skills</NavLink>
          <NavLink href='#certificates'>Certificates</NavLink>
          <NavLink href='#education'>Education</NavLink>
          <NavLink href='#contact'>Contact</NavLink>
        </NavItems>
        <ButtonContainer>
          <LinkedInButton href={Bio.linkedin} target="_blank">Linked<LinkedInImage src='https://cdn-icons-png.flaticon.com/256/174/174857.png'/></LinkedInButton>
          <GitHubButton href={Bio.github} target="_blank">GitHub<GitHubImage src='https://creazilla-store.fra1.digitaloceanspaces.com/icons/7914417/github-icon-md.png'/></GitHubButton>
        </ButtonContainer>
        {
          isOpen &&
          <MobileMenu isOpen={isOpen} ref={menuRef}>
            <MobileLink href="#about" onClick={() => {
              setIsOpen(!isOpen)
            }}>About</MobileLink>
            <MobileLink href='#skills' onClick={() => {
              setIsOpen(!isOpen)
            }}>Skills</MobileLink>
            <MobileLink href='#certificates' onClick={() => {
              setIsOpen(!isOpen)
            }}>Certificates</MobileLink>
            <MobileLink href='#education' onClick={() => {
              setIsOpen(!isOpen)
            }}>Education</MobileLink>
            <MobileLink href='#contact' onClick={() => {
              setIsOpen(!isOpen)
            }}>Contact</MobileLink>
            <LinkedInButton style={{padding: '10px 16px', width: '112px'}} href={Bio.linkedin} target="_blank">Linked<LinkedInImage src='https://cdn-icons-png.flaticon.com/256/174/174857.png'/></LinkedInButton>
            <GitHubButton style={{padding: '10px 16px', width: '112px'}} href={Bio.github} target="_blank">GitHub<GitHubImage src='https://creazilla-store.fra1.digitaloceanspaces.com/icons/7914417/github-icon-md.png'/></GitHubButton>
          </MobileMenu>
        }
      </NavbarContainer>
    </Nav>
  )
}

export default Navbar;
