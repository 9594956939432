import React from 'react'
import { Container, Wrapper, Title, Desc, CardContainer } from './ProfessionalCertificateStyle'
import ProfessionalCertificateCard from '../Cards/ProfessionalCertificateCard'
import { professionalCertificates } from '../../data/constants'

const ProfessionalCertificates = () => {
  return (
    <Container id="certificates">
      <Wrapper>
        <Title>Professional Certificates</Title>
        <Desc>
          Here is the list of certificates received from the next leading brands: Microsoft, Atlassian & GitHub.
        </Desc>
        <CardContainer>
          {professionalCertificates
            .map((certificate, index) => (
              <ProfessionalCertificateCard certificate={certificate} key={index} />
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  )
}

export default ProfessionalCertificates
